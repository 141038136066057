<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <div class="gallery__item"><AppHl>office</AppHl></div>
      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_office/1.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_office/2.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryVideo
              :videopath="'assets/img/gallery/gallery_office/3.mp4'"
            />
          </div>
          <div class="gallery__big">
            <GalleryVideo
              :videopath="'assets/img/gallery/gallery_office/4.mp4'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHl from "@/components/ui/buttons/AppHl.vue";
import GalleryVideo from "./GalleryVideo.vue";

import GalleryPic from "./GalleryPic";
export default {
  components: { GalleryPic, AppHl, GalleryVideo },
  setup() {},
};
</script>

<style></style>
