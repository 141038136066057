<template>
  <div class="lovebrands">
    <div class="lovebrands__wrapper">
      <div class="lovebrands__content">
        <div class="lovebrands__animation"><LoveBrandsAnimationStart /></div>
        <div class="block__text">
          Wir helfen dabei,<br />
          dass aus einem Unternehmen von vielen,<br />
          das eine mit dem OH, JA! wird.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoveBrandsAnimationStart from "./LoveBrandsAnimationStart";
export default {
  components: { LoveBrandsAnimationStart },
  setup() {
    return {};
  },
};
</script>
