<template>
  <div class="video" ref="scrollContainer">
    <video
      :src="videoPath"
      autoplay
      muted
      loop
      playsinline
      webkit-playsinline
    ></video>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ref, onMounted, watchEffect } from "vue";

import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  props: { videopath: String },
  setup(props) {
    const { prefixPath } = getPathFunction();
    const store = useStore();
    const allowScroll = ref(true);
    const isInView = ref(false);
    const scrollContainer = ref(null);
    const startAnimate = ref(false);

    const videoPath = ref(null);

    let scrollT = null;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function loadImage() {
      isInView.value = true;
    }
    function startAnimation() {
      let el = scrollContainer.value;
      // gsap.from('#circle_line_1', { duration: 1,scale: 0.8,  drawSVG: "0%",delay: 0.3 });
      // let els_2 = scrollContainer.value.querySelectorAll(".lba__path--2");
      // let time = { time: 0 };
      tl.set([el], {
        z: 0.02,
        transformPerspective: 100,
        transformStyle: "preserve-3d",
      });

      tl.from(el, { duration: 2, ease: "power4.out", y: 50, opacity: 0 });

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: false, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainer.value,
        scrub: false,
        start: "top 100%",
        // end: "top 50%",
        onUpdate: (self) => {
          if (self.progress.toFixed(3) >= 0) {
            loadImage();
          }
          if (self.progress.toFixed(3) > 0.5) {
            startAnimate.value = true;
          } else {
            startAnimate.value = false;
          }
        },
      });
      scrollT.refresh();
    }

    onMounted(() => {
      if (allowScroll.value) {
        startAnimation();
      }
    });

    watchEffect(() => {
      if (isInView.value) {
        videoPath.value = prefixPath(props.videopath);
      }
    });

    return {
      prefixPath,
      scrollContainer,
      startAnimate,
      store,
      isInView,
      videoPath,
    };
  },
};
</script>

<style lang="scss" scoped>
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
